import { BadgeVariant } from '@contentful/f36-components';

import { SurveyTypeList } from '../locations/page-survey';
import {
    ApiAppointmentStatus,
    ApiEntityStatus,
    ApiNotificationStatus,
    ApiOrderEntityStatus,
    ApiOrderEntityTrackingStatus,
    ApiPhotoCompetitionStatus,
    ApiSubscriptionEntityStatus,
    ApiSubscriptionFrequencyStatus,
} from '../models';

export enum BadgeVariantType {
    Default = 'default',
    Order = 'badgeOrder',
    OrderTracking = 'badgeOrderTracking',
    Subscritption = 'badgeSubscription',
    SubscritptionFrequency = 'badgeSubscriptionFrequency',
    PhotoCompetition = 'badgePhotoCompetition',
    Appointment = 'badgeAppointment',
    Customer = 'badgeCustomer',
    Notification = 'badgeNotification',
}

export const getActiveInactivebadge = (status: boolean) => {
    return status == true ? 'positive' : 'negative';
};

export const getBadgeVariant = (
    status: string,
    type: string = BadgeVariantType.Default
): BadgeVariant => {
    switch (type) {
        case BadgeVariantType.Order:
            switch (status) {
                case ApiOrderEntityStatus.COMPLETE:
                    return 'positive';
                case ApiOrderEntityStatus.CANCELLED:
                    return 'negative';
                case ApiOrderEntityStatus.PROCESSING:
                default:
                    return 'warning';
            }
        case BadgeVariantType.OrderTracking:
            switch (status) {
                case ApiOrderEntityTrackingStatus.DELIVERED:
                    return 'positive';
                case ApiOrderEntityTrackingStatus.PREPARING:
                case ApiOrderEntityTrackingStatus.PROCESSING:
                case ApiOrderEntityTrackingStatus.INTRANSIT:
                default:
                    return 'warning';
            }
        case BadgeVariantType.Subscritption:
            switch (status) {
                case ApiSubscriptionEntityStatus.PENDING:
                case ApiSubscriptionEntityStatus.SUSPENDED:
                    return 'warning';
                case ApiSubscriptionEntityStatus.CANCELLED:
                    return 'negative';
                case ApiSubscriptionEntityStatus.ACTIVE:
                default:
                    return 'positive';
            }
        case BadgeVariantType.SubscritptionFrequency:
            switch (status) {
                case ApiSubscriptionFrequencyStatus.DAILY:
                    return 'positive';
                case ApiSubscriptionFrequencyStatus.WEEKLY:
                    return 'featured';
                case ApiSubscriptionFrequencyStatus.MONTHLY:
                    return 'primary';
                case ApiSubscriptionFrequencyStatus.YEARLY:
                default:
                    return 'warning';
            }
        case BadgeVariantType.PhotoCompetition:
            switch (status) {
                case ApiPhotoCompetitionStatus.ENDED:
                    return 'warning';
                case ApiPhotoCompetitionStatus.RUNNING:
                    return 'positive';
                case ApiPhotoCompetitionStatus.PLANNED:
                    return 'primary';
                case ApiPhotoCompetitionStatus.INACTIVE:
                default:
                    return 'negative';
            }
        case BadgeVariantType.Appointment:
            switch (status) {
                case ApiAppointmentStatus.Confirmed:
                    return 'positive';
                case ApiAppointmentStatus.Pending:
                    return 'primary-filled';
                case ApiAppointmentStatus.Completed:
                    return 'secondary';
                case ApiAppointmentStatus.CancelledByAdmin:
                    return 'warning';
                case ApiAppointmentStatus.CancelledByCustomer:
                    return 'negative';
                case ApiAppointmentStatus.NewAppointment:
                default:
                    return 'primary';
            }
        case BadgeVariantType.Notification:
            switch (status) {
                case ApiNotificationStatus.FAILED:
                    return 'negative';
                case ApiNotificationStatus.SENT:
                    return 'positive';
                case ApiNotificationStatus.QUEUED:
                default:
                    return 'primary';
            }

        case BadgeVariantType.Customer:
        case BadgeVariantType.Default:
        default:
            switch (status) {
                case ApiEntityStatus.ACTIVE:
                    return 'positive';
                case ApiEntityStatus.INACTIVE:
                default:
                    return 'negative';
            }
    }
};

export const getBadgeLabelForAppointment = (status: string) => {
    switch (status) {
        case ApiAppointmentStatus.NewAppointment:
            return 'New appointment';
        case ApiAppointmentStatus.CancelledByAdmin:
            return 'Cancelled by admin';
        case ApiAppointmentStatus.CancelledByCustomer:
            return 'Cancelled by customer';
        case ApiAppointmentStatus.Confirmed:
        case ApiAppointmentStatus.Completed:
        case ApiAppointmentStatus.Pending:
        default:
            return status;
    }
};

export const getBadgeLabelForSurveyType = (type: string) => {
    switch (type) {
        case SurveyTypeList.PostAppointment:
            return 'Post appointment';
        default:
            return '';
    }
};
