import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
    Button,
    Flex,
    Form,
    FormControl,
    Heading,
    Modal,
    Paragraph,
    Skeleton,
    Switch,
    Text,
    TextInput,
} from '@contentful/f36-components';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';

const API_URL = `${ENV.middlewareBaseUrl}/api/v2/admin/loyalty/setting`;

export interface LoyaltySetting {
    guid: string;
    amountSpent: number;
    pointsEarnedOnSpent: number;
    pointsRequired: number;
    rewardAmount: number;
    isActive: boolean;
}

export const LoyaltyAndReward: React.FC = () => {
    // Data domains
    const { runRequest } = useVetdeskMiddleware();

    // Component states
    const [isLoading, setIsLoading] = useState(true);
    const [setting, setSetting] = useState<LoyaltySetting | null>();

    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showDisableModal, setShowDisableModal] = useState(false);

    // Form setup
    const {
        handleSubmit,
        formState: { errors },
        reset,
        register,
        control,
        watch,
    } = useForm();

    // Component defaults
    const isActive = watch('isActive');

    // Component defaults
    const amountSpentWatch = watch('amountSpent');
    const pointsEarnedOnSpentWatch = watch('pointsEarnedOnSpent');
    const rewardAmountWatch = watch('rewardAmount');
    const pointsRequiredWatch = watch('pointsRequired');

    useEffect(() => {
        register('isActive');
        fetchData();
    }, []);

    const fetchData = () => {
        setIsLoading(true);
        setSetting(undefined);

        runRequest('GET', `${API_URL}`).then((res) => {
            const response = res as LoyaltySetting;

            if (response) {
                reset({
                    ...response,
                });
                setSetting(response);
            } else {
                setSetting(null);
            }
            setIsLoading(false);
        });
    };

    const renderFields = () => {
        return (
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Flex
                    flexDirection="column"
                    gap="spacingS"
                    justifyContent="center"
                >
                    <FormControl>
                        <FormControl.Label>Earning Rule</FormControl.Label>
                        <Paragraph>
                            Set the amount customers will receive for every
                            spend.
                        </Paragraph>
                    </FormControl>

                    <Flex
                        flexDirection="row"
                        gap="spacingS"
                        justifyContent="left"
                    >
                        <FormControl>
                            <FormControl.Label>
                                Spent (NZD) <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <TextInput
                                {...register(`amountSpent`, {
                                    required: 'This is required field',
                                })}
                            />
                            {errors.amountSpent && (
                                <Text fontColor="red500">
                                    {errors.amountSpent.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormControl.Label>=</FormControl.Label>
                        </FormControl>
                        <FormControl>
                            <FormControl.Label>
                                Earned (Points){' '}
                                <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <TextInput
                                {...register(`pointsEarnedOnSpent`, {
                                    required: 'This is required field',
                                })}
                            />
                            {errors.pointsEarnedOnSpent && (
                                <Text fontColor="red500">
                                    {errors.pointsEarnedOnSpent.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                    </Flex>

                    <FormControl>
                        Customers earn <b>{pointsEarnedOnSpentWatch} pts</b>{' '}
                        every <b>${amountSpentWatch}</b> spent.
                    </FormControl>

                    <FormControl>
                        <FormControl.Label>Redeeming reward</FormControl.Label>
                        <Paragraph>
                            Set the amount to be redeemed and the number of
                            points a customer needs to achieve to receive a
                            reward.
                        </Paragraph>
                    </FormControl>

                    <Flex
                        flexDirection="row"
                        gap="spacingS"
                        justifyContent="left"
                    >
                        <FormControl>
                            <FormControl.Label>
                                Points required to get reward{' '}
                                <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <TextInput
                                {...register(`pointsRequired`, {
                                    required: 'This is required field',
                                })}
                            />
                            {errors.pointsRequired && (
                                <Text fontColor="red500">
                                    {errors.pointsRequired.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                        <FormControl>
                            <FormControl.Label>=</FormControl.Label>
                        </FormControl>
                        <FormControl>
                            <FormControl.Label>
                                Reward amount (NZD){' '}
                                <Text fontColor="red500">*</Text>
                            </FormControl.Label>
                            <TextInput
                                {...register(`rewardAmount`, {
                                    required: 'This is required field',
                                })}
                            />
                            {errors.rewardAmount && (
                                <Text fontColor="red500">
                                    {errors.rewardAmount.message?.toString()}
                                </Text>
                            )}
                        </FormControl>
                    </Flex>
                    <FormControl>
                        Customers receive <b>${rewardAmountWatch}</b> credit for
                        every <b>{pointsRequiredWatch} pts</b> earned.
                    </FormControl>
                </Flex>
            </Form>
        );
    };

    const onSubmit = async (data: any) => {
        await runRequest('PUT', API_URL, data).then(
            (res) => {
                fetchData();
                setShowSubmitModal(false);
                setShowDisableModal(false);
            },
            (error) => console.warn('Oops', error)
        );
    };

    const confirmSubmitModal = () => (
        <Modal
            isShown={showSubmitModal}
            onClose={() => {
                setShowSubmitModal(false);
            }}
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Confirm Changes"
                        onClose={() => {
                            setShowSubmitModal(false);
                        }}
                    />
                    <Modal.Content>
                        <FormControl>
                            <Text>
                                This change will affect how all customers earn
                                loyalty points. Please review the changes
                                carefully before proceeding
                            </Text>
                        </FormControl>
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                setShowSubmitModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="primary"
                            onClick={() => handleSubmit(onSubmit)()}
                        >
                            Save Changes
                        </Button>
                    </Modal.Controls>
                </>
            )}
        </Modal>
    );

    const confirmDisableModal = () => (
        <Modal
            isShown={showDisableModal}
            onClose={() => {
                setShowDisableModal(false);
            }}
            shouldCloseOnOverlayClick={false}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Disable Loyalty & Rewards"
                        onClose={() => {
                            setShowDisableModal(false);
                        }}
                    />
                    <Modal.Content>
                        <FormControl>
                            <Text>
                                Once you disable the loyalty program, customers
                                will no longer be able to use their earned
                                points. Only vouchers will remain visible in the
                                app, and all point transactions will be deleted.
                            </Text>
                        </FormControl>
                    </Modal.Content>
                    <Modal.Controls>
                        <Button
                            size="small"
                            variant="transparent"
                            onClick={() => {
                                setShowDisableModal(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            size="small"
                            variant="negative"
                            onClick={() => handleSubmit(onSubmit)()}
                        >
                            Disable
                        </Button>
                    </Modal.Controls>
                </>
            )}
        </Modal>
    );

    return !isLoading ? (
        <>
            {/* <Note style={{ marginBottom: 20 }}>Important note</Note> */}
            <Heading>Enable Loyalty and Rewards Program</Heading>
            <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                    <Switch isChecked={field.value} onChange={field.onChange}>
                        {field.value == true ? 'On' : 'Off'}
                    </Switch>
                )}
            />

            <Paragraph style={{ marginTop: 10 }}>
                Automatically send rewards to your customer’s App every time
                they reach the spending milestone set by you.{' '}
            </Paragraph>
            {isActive && renderFields()}
            <Button
                size="small"
                variant="primary"
                onClick={() =>
                    isActive
                        ? setShowSubmitModal(true)
                        : setShowDisableModal(true)
                }
            >
                Save
            </Button>
            {confirmSubmitModal()}
            {confirmDisableModal()}
        </>
    ) : (
        <Skeleton.Container>
            <Skeleton.BodyText numberOfLines={4} />
        </Skeleton.Container>
    );
};
