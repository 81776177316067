import React from 'react';

import { PhotoCompetitionPreviewImage } from '../models/photoCompetitions';

const ImagePicker: React.FC<{
    onChange: (guid: string | null) => void;
    value: string | null;
    imageList: PhotoCompetitionPreviewImage[] | undefined;
}> = ({ onChange, value, imageList }) => {
    const handleImageSelect = (guid: string) => {
        onChange(guid);
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
                {imageList &&
                    imageList.map((image) => (
                        <div
                            key={image.previewImageGuid}
                            onClick={() =>
                                handleImageSelect(image.previewImageGuid)
                            }
                            style={{
                                cursor: 'pointer',
                                border:
                                    value === image.previewImageGuid
                                        ? '2px solid blue'
                                        : '2px solid #ddd',
                                borderRadius: '10px',
                                padding: '5px',
                                textAlign: 'center',
                            }}
                        >
                            <img
                                src={image.previewImageUrl}
                                alt={`Preview ${image.previewImageGuid}`}
                                style={{
                                    width: '150px',
                                    height: '120px',
                                    objectFit: 'cover',
                                    borderRadius: '10px',
                                    marginBottom: '5px',
                                }}
                            />
                            <p>
                                {value === image.previewImageGuid
                                    ? 'Selected'
                                    : 'Tap to Select'}
                            </p>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default ImagePicker;
