import React, { useEffect, useState } from 'react';

import {
    Button,
    Card,
    CopyButton,
    Flex,
    FormControl,
    Heading,
    Modal,
    Paragraph,
    Skeleton,
    Stack,
    Text,
    TextInput,
    TextLink,
} from '@contentful/f36-components';
import { DownloadIcon, SearchIcon } from '@contentful/f36-icons';

import { PaginationWrapper } from '../components/pagination-wrapper';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiListResponse } from '../models';
import { FetchListFilters } from '../utilities/fetch-filters';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/payload/articles`;

export interface Article {
    id: string;
    contentName: string;
    categories: ArticleCategory[];
    summary: string;
    content: string;
    featuredImage?: string;
    slug?: string;
}

export interface ArticleCategory {
    name: string;
    value: string;
}

export interface ArticleFetchListFilters extends FetchListFilters {
    category?: string[];
}

const customStyle = {
    defaultLabel: { minWidth: 100 },
    defaultFormControl: { marginBottom: 0 },
};

export const PageContentLibrary = () => {
    const { fetchEntityList, runRequest } = useVetdeskMiddleware<Article>();

    const [list, setList] = useState<ApiListResponse<Article> | undefined>(
        undefined
    );

    const [categories, setCategories] = useState<ArticleCategory[] | undefined>(
        undefined
    );

    const [selectedArticle, setSelectedArticle] = useState<Article | undefined>(
        undefined
    );

    const [filters, setFilters] = useState<ArticleFetchListFilters>({
        search: '',
        limit: 10,
        offset: 0,
        sortBy: 'updatedAt',
        sortOrderList: 'desc',
        category: [],
    });

    const [searchInput, setSearchInput] = useState<string>('');

    const debouncedSearchInput = useDebounce<string>(searchInput, 500);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const fetchList = () => {
        setList(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}${getCustomQueryString(filters)}`).then(
            (res) => {
                setList(res);
            }
        );
    };

    const getCustomQueryString = (obj: ArticleFetchListFilters) => {
        const queryParams = new URLSearchParams({
            offset: obj.offset.toString(),
            limit: obj.limit.toString(),
        });

        // Add search query parameter if it's not empty
        if (obj.search) {
            queryParams.append('search', obj.search);
        }

        // Add each category as a separate 'category' key in the query string
        if (obj.category && obj.category.length > 0) {
            obj.category.forEach((category) => {
                queryParams.append('category', category);
            });
        }

        // Add sortBy and sortOrderList query parameters if provided
        if (obj.sortBy) {
            queryParams.append('sortBy', obj.sortBy);
        }

        if (obj.sortOrderList) {
            queryParams.append('sortOrderList', obj.sortOrderList);
        }

        // Convert to query string
        return `?${queryParams.toString()}`;
    };

    const fetchCategories = () => {
        runRequest('GET', `${API_URL}/categories`).then((res) => {
            // rename categories
            setCategories(res as ArticleCategory[]);
        });
    };

    useEffect(() => {
        fetchList();
    }, [filters]);

    useEffect(() => {
        fetchCategories();
    }, []);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            console.log('debouncedSearchInput', debouncedSearchInput);
            setFilters((prevFilters) => ({
                ...prevFilters,
                search: debouncedSearchInput,
                offset: 0,
            }));
        }
    }, [debouncedSearchInput]);

    // Function to add a category only if it doesn't already exist
    const handleAddCategoryToFilter = (category: string) => {
        setFilters((prevFilters) => {
            const newCategoryArray = prevFilters.category?.includes(category)
                ? prevFilters.category.filter((cat) => cat !== category) // Remove the category if it exists
                : [...(prevFilters.category as []), category]; // Add the category if it doesn't exist

            return {
                ...prevFilters,
                category: newCategoryArray,
            };
        });
    };

    const renderCategories = () => {
        return (
            <Stack
                style={{ margin: '20px 0px' }}
                spacing="spacingS"
                flexWrap="wrap"
            >
                <Text>Filter by:</Text>
                <Button
                    size="small"
                    onClick={() =>
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            category: [],
                        }))
                    }
                    variant={
                        filters.category?.length == 0 ? 'primary' : 'secondary'
                    }
                >
                    All
                </Button>
                {categories?.map((x, i) => (
                    <Button
                        key={i}
                        size="small"
                        variant={
                            filters.category?.includes(x.value)
                                ? 'primary'
                                : 'secondary'
                        }
                        onClick={() => handleAddCategoryToFilter(x.value)}
                    >
                        {x.name}
                    </Button>
                ))}
            </Stack>
        );
    };

    const handleImageDownload = async (article: Article) => {
        try {
            const response = await fetch(article.featuredImage as string, {
                mode: 'cors',
            });

            if (!response.ok) {
                throw new Error('Failed to fetch image');
            }

            const blob = await response.blob();

            // Create an image element to load the blob
            const img = new Image();
            img.src = URL.createObjectURL(blob);

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;

                // Draw the image onto the canvas
                ctx?.drawImage(img, 0, 0);

                // Export the canvas content as a PNG Data URL
                const dataUrl = canvas.toDataURL('image/png');

                // Create a download link
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = `${article.slug || 'downloaded-image'}.png`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // Clean up
                URL.revokeObjectURL(img.src);
            };
        } catch (error) {
            console.error('Error downloading the image as PNG:', error);
        }
    };

    const renderArticles = () => {
        if (!list?.items?.length) {
            return <Text>No articles available</Text>;
        }

        return (
            <Stack
                className="article-stack"
                spacing="spacingS"
                flexWrap="wrap"
                style={{ margin: '20px 0px' }}
            >
                {list.items.map((x) => (
                    <Card
                        key={x.id || x.contentName}
                        style={{
                            maxWidth: 300,
                            height: 360,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            setSelectedArticle(x);
                        }}
                    >
                        <img
                            alt={x.contentName || 'Article image'}
                            src={x.featuredImage || 'placeholder-image.jpg'}
                            style={{
                                width: '100%',
                                height: '250px',
                                borderRadius: '5px',
                                objectFit: 'cover',
                            }}
                        />
                        <Paragraph
                            marginTop="spacingXs"
                            marginBottom="spacingXs"
                        >
                            <Text>
                                {x.categories?.map((c) => c.name).join(', ')}
                            </Text>
                        </Paragraph>
                        <TextLink as="button" variant="secondary">
                            {x.contentName}
                        </TextLink>
                    </Card>
                ))}
            </Stack>
        );
    };

    const handleCopyToDraftArticle = (article: Article) => {
        runRequest('GET', `${API_URL}/${article.id}/copy-to-website`).then(
            (res) => {
                setSelectedArticle(undefined);
            }
        );
    };

    const renderModal = () => {
        return (
            <Modal
                onClose={() => setSelectedArticle(undefined)}
                isShown={!!selectedArticle}
                size="900px"
                shouldCloseOnOverlayClick={false}
            >
                {() => (
                    <>
                        <Modal.Header
                            title="View Content"
                            onClose={() => {
                                setSelectedArticle(undefined);
                            }}
                        />
                        <Modal.Content>
                            <FormControl style={customStyle.defaultFormControl}>
                                <FormControl.Label
                                    style={customStyle.defaultLabel}
                                >
                                    Title:
                                </FormControl.Label>
                                <Text>{selectedArticle?.contentName}</Text>
                            </FormControl>

                            <FormControl style={customStyle.defaultFormControl}>
                                <FormControl.Label
                                    style={customStyle.defaultLabel}
                                >
                                    Category:
                                </FormControl.Label>
                                <Text>
                                    {selectedArticle?.categories
                                        ?.map((c) => c.name)
                                        .join(', ')}
                                </Text>
                            </FormControl>
                            <hr />
                            <FormControl style={customStyle.defaultFormControl}>
                                <FormControl.Label
                                    style={customStyle.defaultLabel}
                                >
                                    Image
                                </FormControl.Label>
                                <img
                                    alt={
                                        selectedArticle?.contentName ||
                                        'Article image'
                                    }
                                    src={
                                        selectedArticle?.featuredImage ||
                                        'placeholder-image.jpg'
                                    }
                                    style={{
                                        width: '30%',
                                        height: 'auto',
                                        borderRadius: '5px',
                                    }}
                                />
                                <Button
                                    style={{ marginTop: 20, marginBottom: 20 }}
                                    startIcon={<DownloadIcon />}
                                    size="small"
                                    onClick={() =>
                                        selectedArticle &&
                                        handleImageDownload(selectedArticle)
                                    }
                                >
                                    Download image
                                </Button>
                            </FormControl>
                            <FormControl style={customStyle.defaultFormControl}>
                                <FormControl.Label
                                    style={customStyle.defaultLabel}
                                >
                                    Content{' '}
                                    <CopyButton
                                        size="small"
                                        label="Copy content"
                                        value={
                                            selectedArticle?.content as string
                                        }
                                        tooltipCopiedText="The content has been copied"
                                        tooltipText="Copy this content to clipboard"
                                        tooltipProps={{
                                            placement: 'right',
                                            usePortal: true,
                                        }}
                                    ></CopyButton>
                                </FormControl.Label>
                                <Card
                                    style={{
                                        maxHeight: '400px',
                                        overflowY: 'auto',
                                    }}
                                >
                                    <div
                                        className="article-content"
                                        dangerouslySetInnerHTML={{
                                            __html: selectedArticle?.content as string,
                                        }}
                                    />
                                </Card>
                            </FormControl>
                        </Modal.Content>
                        <Modal.Controls>
                            <Button
                                size="small"
                                variant="primary"
                                onClick={() =>
                                    selectedArticle &&
                                    handleCopyToDraftArticle(selectedArticle)
                                }
                            >
                                Copy to Draft Article
                            </Button>
                            <Button
                                size="small"
                                variant="secondary"
                                onClick={() => {
                                    setSelectedArticle(undefined);
                                }}
                            >
                                Close
                            </Button>
                        </Modal.Controls>
                    </>
                )}
            </Modal>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Content Library</Heading>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search by content name"
                    icon={<SearchIcon />}
                />
                {categories ? (
                    renderCategories()
                ) : (
                    <Skeleton.Row rowCount={1} columnCount={5} />
                )}
                {list ? (
                    <>
                        {renderArticles()}
                        <PaginationWrapper
                            onPageChange={handlePageChange}
                            filters={filters}
                            totalItems={list.total}
                        />
                    </>
                ) : (
                    <Skeleton.Row rowCount={5} columnCount={5} />
                )}
            </Flex>
            {selectedArticle && renderModal()}
        </>
    );
};
