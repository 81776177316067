import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
    Button,
    Datepicker,
    Modal,
    Text,
    Textarea,
} from '@contentful/f36-components';
import { Form, FormControl, TextInput } from '@contentful/f36-forms';

import { ENV } from '../../environments';
import { useVetdeskMiddleware } from '../../hooks';
import { FormPhotoCompetitionProps } from '../../models/photoCompetitions';
import { formatDateToISOString } from '../../utilities/date-helper';
import ImagePicker from '../image-picker';

export const FormPhotoCompetition: React.FC<FormPhotoCompetitionProps> = (
    props
) => {
    const { formData, isVisible, setVisibility, setRefresh, setFormData } =
        props;

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
        register,
    } = useForm();

    const { updateEntity, createEntity } = useVetdeskMiddleware();

    useEffect(() => {
        reset(formData || {});
    }, [formData]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (data: any) => {
        console.log('Submitted Daya', data);
        (formData && formData.id
            ? updateEntity(
                  `${ENV.middlewareBaseUrl}/api/v1/admin/event`,
                  formData.id,
                  {
                      ...formData,
                      ...data,
                      startDate: formatDateToISOString(
                          new Date(data.startDate)
                      ),
                      endDate: formatDateToISOString(new Date(data.endDate)),
                  }
              )
            : createEntity(`${ENV.middlewareBaseUrl}/api/v1/admin/event`, {
                  ...data,
                  startDate: formatDateToISOString(new Date(data.startDate)),
                  endDate: formatDateToISOString(new Date(data.endDate)),
              })
        ).then(
            () => {
                reset({});
                setRefresh(true);
                setVisibility(false);
            },
            (error) => console.warn('Oops', error)
        );
    };

    return (
        <Modal
            onClose={() => setVisibility(false)}
            isShown={isVisible}
            size="fullWidth"
            shouldCloseOnOverlayClick={false}
        >
            {() => {
                const startDateDisabled = false;
                const endDateDisabled = false;
                if (formData && formData.status) {
                    // allowing vet to update dates. Need to review later
                    // startDateDisabled =
                    //     formData.status === ApiPhotoCompetitionStatus.RUNNING
                    //         ? true
                    //         : formData.status ===
                    //               ApiPhotoCompetitionStatus.ENDED ||
                    //           formData.status ===
                    //               ApiPhotoCompetitionStatus.INACTIVE
                    //         ? true
                    //         : false;
                    // endDateDisabled =
                    //     formData.status === ApiPhotoCompetitionStatus.ENDED ||
                    //     formData.status === ApiPhotoCompetitionStatus.INACTIVE
                    //         ? true
                    //         : false;
                }

                return (
                    <Form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ overflowY: 'scroll', minHeight: 800 }}
                    >
                        <Modal.Header
                            title={`${
                                formData && formData.id ? 'Edit' : 'Add New'
                            } Photo Competition`}
                            onClose={() => setVisibility(false)}
                        />
                        <Modal.Content>
                            <FormControl style={{ marginTop: 20 }}>
                                <FormControl.Label>
                                    Event Title{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <TextInput {...register(`title`)} />
                            </FormControl>
                            <FormControl>
                                <FormControl.Label>
                                    Event Description{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Textarea {...register(`description`)} />
                            </FormControl>
                            <FormControl>
                                <FormControl.Label>
                                    Event Prize{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <TextInput {...register(`prize`)} />
                            </FormControl>
                            <FormControl style={{ minWidth: 120 }}>
                                <FormControl.Label>
                                    Start Date <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Datepicker
                                    selected={
                                        formData && formData.startDate
                                            ? new Date(formData.startDate)
                                            : new Date()
                                    }
                                    onSelect={(val) => {
                                        const data = {
                                            ...formData,
                                            startDate: val,
                                        };
                                        setFormData(data);
                                    }}
                                    inputProps={{
                                        isDisabled: startDateDisabled,
                                    }}
                                />
                            </FormControl>
                            <FormControl style={{ minWidth: 120 }}>
                                <FormControl.Label>
                                    End Date <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Datepicker
                                    selected={
                                        formData && formData.endDate
                                            ? new Date(formData.endDate)
                                            : new Date()
                                    }
                                    onSelect={(val) => {
                                        const data = {
                                            ...formData,
                                            endDate: val,
                                        };
                                        setFormData(data);
                                    }}
                                    inputProps={{
                                        isDisabled: endDateDisabled,
                                    }}
                                />
                            </FormControl>
                            <Controller
                                name="previewImageGuid"
                                control={control}
                                render={({ field }) => (
                                    <FormControl>
                                        <FormControl.Label>
                                            Event preview image{' '}
                                            <Text fontColor="red500">*</Text>
                                        </FormControl.Label>

                                        <ImagePicker
                                            onChange={field.onChange}
                                            value={field.value}
                                            imageList={props.previewImages}
                                        />
                                    </FormControl>
                                )}
                            />
                            <FormControl
                                style={{ minWidth: 120, marginTop: 20 }}
                            >
                                <FormControl.Label>
                                    Terms and conditions{' '}
                                    <Text fontColor="red500">*</Text>
                                </FormControl.Label>
                                <Textarea rows={15} {...register(`terms`)} />
                            </FormControl>
                        </Modal.Content>
                        <Modal.Controls>
                            <Button
                                size="small"
                                variant="transparent"
                                onClick={() => {
                                    reset();
                                    setVisibility(false);
                                }}
                            >
                                Close
                            </Button>
                            <Button
                                size="small"
                                variant="positive"
                                type="submit"
                            >
                                {formData && formData.id ? 'Save' : 'Create'}
                            </Button>
                        </Modal.Controls>
                    </Form>
                );
            }}
        </Modal>
    );
};
