import React from 'react';

import { Flex, Heading, Paragraph } from '@contentful/f36-components';

import { LoyaltyAndReward } from '../components/settings/loyalty-and-reward';

export const PageLoyaltyAndRewards = () => {
    return (
        <>
            <Flex flexDirection="column" alignItems="left">
                <Heading as="h1">Loyalty & Rewards</Heading>
                <Paragraph>
                    Increase customer loyalty with your own rewards program.
                </Paragraph>
            </Flex>
            <Flex className="spacingTop" flexDirection="column">
                <LoyaltyAndReward />
            </Flex>
        </>
    );
};
