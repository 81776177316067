import React, { useEffect, useState } from 'react';

import {
    Button,
    Flex,
    Heading,
    ModalConfirm,
    Stack,
    Switch,
    Text,
    TextInput,
} from '@contentful/f36-components';
import { PlusIcon, SearchIcon } from '@contentful/f36-icons';

import { FormPhotoCompetition } from '../components/forms/form-photo-competition';
import { PaginationWrapper } from '../components/pagination-wrapper';
import { PhotoCompetitionDetail } from '../components/photo-competition-detail';
import { TableList } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiListResponse } from '../models';
import {
    LeaderboardConfig,
    PhotoCompetition,
    PhotoCompetitionPreviewImage,
} from '../models/photoCompetitions';
import {
    DEFAULT_FETCH_LIST_FILTERS,
    FetchListFilters,
    getQueryString,
} from '../utilities/fetch-filters';
import { PHOTO_COMPETITION_EVENT_COLUMNS } from '../utilities/table-columns';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/admin/`;

export const PagePhotoCompetitions = () => {
    const { fetchEntityList, runRequest } = useVetdeskMiddleware();
    const [photoCompetitions, setPhotoCompetitions] = useState<
        ApiListResponse<PhotoCompetition> | undefined
    >(undefined);
    const [refresh, setRefresh] = useState(false);

    const [filters, setFilters] = useState<FetchListFilters>(
        DEFAULT_FETCH_LIST_FILTERS
    );

    const [previewImages, setPreviewImages] = useState<
        PhotoCompetitionPreviewImage[] | undefined
    >(undefined);

    const [config, setConfig] = useState<LeaderboardConfig | undefined>(
        undefined
    );
    const [showBannerModal, setShowBannerModal] = useState(false);

    const [searchInput, setSearchInput] = useState<string>('');
    const debouncedSearchInput = useDebounce<string>(searchInput, 500);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState(null);

    const [showDetail, setShowDetail] = useState(false);

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handleUpsert = (formData?: any) => {
        setFormData(formData || null);
        setShowForm(true);
    };

    const fetchPhotoCompetitions = () => {
        setPhotoCompetitions(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}event/${getQueryString(filters)}`).then(
            (res) => {
                setPhotoCompetitions(res as ApiListResponse<PhotoCompetition>);
            }
        );
    };

    const fetchPreviewImages = () => {
        runRequest('GET', `${API_URL}preview-images`).then((res) => {
            setPreviewImages(res as PhotoCompetitionPreviewImage[]);
        });
    };

    const fetchLeaderboardConfig = () => {
        runRequest('GET', `${API_URL}leaderboard-config`).then((res) => {
            setConfig(res as LeaderboardConfig);
        });
    };

    const handleViewDetail = (formData?: any) => {
        setFormData(formData || null);
        setShowDetail(true);
    };

    useEffect(() => {
        fetchPreviewImages();
        fetchLeaderboardConfig();
    }, []);

    useEffect(() => {
        fetchPhotoCompetitions();
    }, [filters]);

    useEffect(() => {
        if (refresh) {
            fetchPhotoCompetitions();
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            setFilters({
                ...filters,
                search: debouncedSearchInput,
                offset: 0,
            });
        }
    }, [debouncedSearchInput]);

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Photo Competitions</Heading>

                <Stack justifyContent="space-between">
                    {config && (
                        <div style={{ marginTop: -25 }}>
                            <Switch
                                isChecked={config.isLeaderboardBannerVisible}
                                onChange={() => {
                                    setShowBannerModal(true);
                                }}
                            >
                                Display the banner in the app
                            </Switch>
                        </div>
                    )}

                    <Button
                        startIcon={<PlusIcon />}
                        variant="primary"
                        size="medium"
                        className="addButton"
                        onClick={() => handleUpsert()}
                    >
                        Add New
                    </Button>
                </Stack>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="Content type name"
                    id="searchString"
                    placeholder="Search..."
                    icon={<SearchIcon />}
                />
                <TableList<PhotoCompetition>
                    columns={PHOTO_COMPETITION_EVENT_COLUMNS}
                    items={photoCompetitions?.items}
                    onEdit={handleUpsert}
                    onEditCustomLabel="Edit event"
                    onView={handleViewDetail}
                    onViewCustomLabel="View entries"
                />
                {photoCompetitions && (
                    <PaginationWrapper
                        onPageChange={handlePageChange}
                        filters={filters}
                        totalItems={photoCompetitions.total}
                    />
                )}
            </Flex>
            {showForm && (
                <FormPhotoCompetition
                    formData={formData}
                    setRefresh={setRefresh}
                    setVisibility={setShowForm}
                    isVisible={showForm}
                    setFormData={setFormData}
                    previewImages={previewImages}
                />
            )}
            {showDetail && (
                <PhotoCompetitionDetail
                    setVisibility={setShowDetail}
                    isVisible={showDetail}
                    formData={formData}
                    setRefresh={() => true}
                />
            )}

            {showBannerModal && (
                <ModalConfirm
                    intent="positive"
                    isShown={showBannerModal}
                    onCancel={() => {
                        setShowBannerModal(false);
                    }}
                    onConfirm={() => {
                        if (config) {
                            const data: LeaderboardConfig = {
                                isLeaderboardBannerVisible:
                                    !config.isLeaderboardBannerVisible,
                            };

                            runRequest(
                                'PUT',
                                `${API_URL}leaderboard-config`,
                                data
                            ).then((res) => {
                                setConfig(res as LeaderboardConfig);
                                setShowBannerModal(false);
                            });
                        } else {
                            setShowBannerModal(false);
                        }
                    }}
                >
                    <Text>
                        {config?.isLeaderboardBannerVisible
                            ? 'Turning this off will hide the Photo Competition in the app.'
                            : 'Turning this on will display the Photo Competition in the app.'}
                    </Text>
                </ModalConfirm>
            )}
        </>
    );
};
