import '../styles/page.css';

import React, { useState } from 'react';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    Accordion,
    Box,
    Button,
    Flex,
    Popover,
    SectionHeading,
    Text,
} from '@contentful/f36-components';
import {
    AssetIcon,
    CalendarIcon,
    ClockIcon,
    CycleIcon,
    EmbeddedEntryInlineIcon,
    EntryIcon,
    GiftIcon,
    InfoCircleIcon,
    LogoutIcon,
    PersonIcon,
    ReceiptIcon,
    RichtextIcon,
    ShoppingCartIcon,
    SpreadsheetIcon,
    TagsIcon,
    UsersIcon,
} from '@contentful/f36-icons';
import {
    Workbench,
    WorkbenchContent,
    WorkbenchHeader,
    WorkbenchSidebar,
} from '@contentful/f36-workbench';

import { useAuth } from '../auth/authProvider';
import { ENV } from '../environments';
import { PageAppointments } from './page-appointments';
import { PageAutoReminders } from './page-auto-reminders';
import { PageBookingForm } from './page-booking-form';
import { PageCategories } from './page-categories';
import { PageContentLibrary } from './page-content-library';
import { PageCoupons } from './page-coupons';
import { PageCustomers } from './page-customers';
import { PageDashboard } from './page-dashboard';
import { PageFundraisers } from './page-fundraisers';
import { PageLoyaltyAndRewards } from './page-loyalty-and-rewards';
import { PageNotifications } from './page-notifications';
import { PageOneoffInvoices } from './page-oneoff-invoices';
import { PageOrders } from './page-orders';
import { PagePhotoCompetitions } from './page-photo-competition';
import { PageProducts } from './page-products';
import { PageSubscriptions } from './page-subscriptions';
import { PageSurvey } from './page-survey';

export const NavigationLocation = {
    photoCompetitions: 'photoCompetitions-nav',
    appointments: 'appointments-nav',
    customers: 'customers-nav',
    orders: 'orders-nav',
    subscriptions: 'subscriptions-nav',
};

export const navigateHelper = (location: string) => {
    const navigationButton: HTMLElement = document.getElementsByClassName(
        location
    )[0] as HTMLElement;

    navigationButton && navigationButton.click();
};

export const ContentLocationSettings = {
    dashboard: PageDashboard,
    categories: PageCategories,
    customers: PageCustomers,
    products: PageProducts,
    coupons: PageCoupons,
    orders: PageOrders,
    subscriptions: PageSubscriptions,
    'oneoff-invoices': PageOneoffInvoices,
    fundraisers: PageFundraisers,
    'photo-competitions': PagePhotoCompetitions,
    appointments: PageAppointments,
    loyalty: PageLoyaltyAndRewards,
    notifications: PageNotifications,
    'booking-form': PageBookingForm,
    'auto-reminders': PageAutoReminders,
    'content-library': PageContentLibrary,
    survey: PageSurvey,
};

const MenuEntries = [
    {
        label: 'Dashboard',
        location: 'dashboard',
        icon: InfoCircleIcon,
    },
    {
        label: 'Clinic',
        isExpanded: true,
        items: [
            {
                label: 'Customers',
                location: 'customers',
                icon: UsersIcon,
            },
            {
                label: 'Appointments',
                location: 'appointments',
                icon: CalendarIcon,
            },
            {
                label: 'Notifications',
                location: 'notifications',
                icon: ClockIcon,
            },
            {
                label: 'Content Library',
                location: 'content-library',
                icon: RichtextIcon,
            },
            {
                label: 'Survey',
                location: 'survey',
                icon: SpreadsheetIcon,
            },
        ],
    },
    {
        label: 'Settings',
        isExpanded: true,
        items: [
            {
                label: 'Booking Form',
                location: 'booking-form',
                icon: ReceiptIcon,
            },
            {
                label: 'Auto Reminders',
                location: 'auto-reminders',
                icon: ClockIcon,
            },
            {
                label: 'Photo Contest',
                location: 'photo-competitions',
                icon: AssetIcon,
            },
            {
                label: 'Rewards',
                location: 'loyalty',
                icon: GiftIcon,
            },
        ],
    },
    {
        label: 'E-Commerce',
        isExpanded: true,
        items: [
            {
                label: 'Categories',
                location: 'categories',
                icon: TagsIcon,
            },
            {
                label: 'Products',
                location: 'products',
                icon: EntryIcon,
            },
            {
                label: 'Coupons',
                location: 'coupons',
                icon: EmbeddedEntryInlineIcon,
            },
            {
                label: 'Orders',
                location: 'orders',
                icon: ShoppingCartIcon,
            },
            {
                label: 'Subscriptions',
                location: 'subscriptions',
                icon: CycleIcon,
            },
            {
                label: 'One-off Payments',
                location: 'oneoff-invoices',
                icon: ReceiptIcon,
            },
            {
                label: 'Fundraisers',
                location: 'fundraisers',
                icon: GiftIcon,
            },
        ],
    },
];

const Page = () => {
    const location = useLocation(); // Get the current route location
    const navigate = useNavigate(); // Function to navigate programmatically

    const [accountPopOpen, setAccountPopOpen] = useState(false);

    const { profile, signoutRedirect } = useAuth();

    return (
        <Workbench>
            <WorkbenchHeader
                title="Vetdesk Admin"
                actions={
                    <Popover
                        isOpen={accountPopOpen}
                        onClose={() => setAccountPopOpen(false)}
                    >
                        <Popover.Trigger>
                            <Button
                                startIcon={<PersonIcon />}
                                size="small"
                                onClick={() =>
                                    setAccountPopOpen(!accountPopOpen)
                                }
                            >
                                {profile && profile.email}
                            </Button>
                        </Popover.Trigger>
                        <Popover.Content>
                            <Box padding="spacingM">
                                <Button
                                    // isLoading={isLoading}
                                    // isDisabled={isLoading}
                                    startIcon={<LogoutIcon variant={'muted'} />}
                                    onClick={signoutRedirect}
                                    size="small"
                                >
                                    <Text
                                        fontWeight="fontWeightDemiBold"
                                        fontColor={'gray700'}
                                    >
                                        Sign Out
                                    </Text>
                                </Button>
                            </Box>
                        </Popover.Content>
                    </Popover>
                }
            ></WorkbenchHeader>
            <WorkbenchSidebar position="left" className="fullWidthAside">
                {MenuEntries.map((entry) => {
                    if (entry.items) {
                        return (
                            <Accordion key={`group-${entry.label}`}>
                                <Accordion.Item
                                    title={
                                        <SectionHeading marginBottom="none">
                                            {entry.label}
                                        </SectionHeading>
                                    }
                                    isExpanded={entry.isExpanded}
                                >
                                    <Flex flexDirection="column">
                                        {entry.items.map((item) => {
                                            return (
                                                <Button
                                                    key={`action-${item.label}`}
                                                    startIcon={
                                                        <item.icon
                                                            variant={
                                                                location.pathname ===
                                                                `/${item.location}`
                                                                    ? 'primary'
                                                                    : 'muted'
                                                            }
                                                        />
                                                    }
                                                    variant="transparent"
                                                    isFullWidth
                                                    className={`leftNavButton ${item.location}-nav`}
                                                    onClick={() =>
                                                        navigate(
                                                            `/${item.location}`
                                                        )
                                                    }
                                                >
                                                    <Text
                                                        fontWeight="fontWeightDemiBold"
                                                        fontColor={
                                                            location.pathname ===
                                                            `/${item.location}`
                                                                ? 'colorPrimary'
                                                                : 'gray700'
                                                        }
                                                    >
                                                        {item.label}
                                                    </Text>
                                                </Button>
                                            );
                                        })}
                                    </Flex>
                                </Accordion.Item>
                            </Accordion>
                        );
                    } else {
                        return (
                            <Button
                                key={`action-${entry.label}`}
                                startIcon={
                                    <entry.icon
                                        variant={
                                            location.pathname ===
                                            `/${entry.location}`
                                                ? 'primary'
                                                : 'muted'
                                        }
                                    />
                                }
                                variant="transparent"
                                isFullWidth
                                className="dashboardButton"
                                onClick={() => navigate(entry.location)}
                            >
                                <Text
                                    fontWeight="fontWeightDemiBold"
                                    fontColor={
                                        location.pathname ===
                                        `/${entry.location}`
                                            ? 'colorPrimary'
                                            : 'gray700'
                                    }
                                >
                                    {entry.label}
                                </Text>
                            </Button>
                        );
                    }
                })}
                <Accordion>
                    <Accordion.Item
                        title={
                            <SectionHeading marginBottom="none">
                                Website
                            </SectionHeading>
                        }
                        isExpanded={true}
                    >
                        <Flex flexDirection="column">
                            <Button
                                startIcon={<ReceiptIcon />}
                                variant="transparent"
                                isFullWidth
                                className={`leftNavButton`}
                                onClick={() =>
                                    (window.location.href = ENV.cmsUrl)
                                }
                            >
                                <Text
                                    fontWeight="fontWeightDemiBold"
                                    fontColor={'gray700'}
                                >
                                    Manage Content
                                </Text>
                            </Button>
                        </Flex>
                    </Accordion.Item>
                </Accordion>
            </WorkbenchSidebar>
            <WorkbenchContent>
                <Routes>
                    <Route path="/" element={<Navigate to="dashboard" />} />
                    {Object.entries(ContentLocationSettings).map(
                        ([path, Component]) => (
                            <Route
                                key={path}
                                path={`/${path}`}
                                element={<Component />}
                            />
                        )
                    )}
                </Routes>
            </WorkbenchContent>
        </Workbench>
    );
};

export default Page;
