import React, { useState } from 'react';

import { Flex, Heading, Paragraph, Tabs } from '@contentful/f36-components';

import {
    ReminderTypeEnum,
    UpcomingReminders,
} from '../components/settings/reminders/upcoming-reminder';

export const personalisationTokens = {
    '{{APPOINTMENT_DATE}}': 'Appointment Date',
    '{{APPOINTMENT_TIME}}': 'Appointment Time',
    '{{CLIENT_NAME}}': 'Client Name',
    '{{PET_NAME}}': 'Pet Name',
    '{{VET_NAME}}': 'Vet Name',
};

export interface ReminderType {
    id: string;
    name: string;
    identifier: string;
}

export const PageAutoReminders = () => {
    const [currentTab, setCurrentTab] = useState('UpcomingAppointments');
    return (
        <>
            <Flex flexDirection="column" alignItems="left">
                <Heading as="h1">Auto Reminders</Heading>
                <Paragraph>Information about reminders</Paragraph>
            </Flex>
            <Flex className="spacingTop" flexDirection="column">
                <Tabs
                    currentTab={currentTab}
                    onTabChange={(e: string) => setCurrentTab(e)}
                >
                    <Tabs.List style={{ marginBottom: 20 }}>
                        <Tabs.Tab panelId="UpcomingAppointments">
                            Upcoming appointments
                        </Tabs.Tab>
                        <Tabs.Tab panelId="General">General</Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel id="UpcomingAppointments">
                        <UpcomingReminders
                            type={ReminderTypeEnum.APPOINTMENT}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel id="General">
                        <UpcomingReminders type={ReminderTypeEnum.GENERAL} />
                    </Tabs.Panel>
                </Tabs>
            </Flex>
        </>
    );
};
