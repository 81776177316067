import React, { useEffect, useState } from 'react';

import {
    Button,
    Flex,
    formatDateAndTime,
    FormControl,
    Heading,
    Modal,
    Skeleton,
    Stack,
    Text,
    TextInput,
} from '@contentful/f36-components';
import { SearchIcon } from '@contentful/f36-icons';

import { PaginationWrapper } from '../components/pagination-wrapper';
import { TableList } from '../components/table-list';
import { ENV } from '../environments';
import { useDebounce, useVetdeskMiddleware } from '../hooks';
import { ApiBaseEntity, ApiListResponse } from '../models';
import { getBadgeLabelForSurveyType } from '../utilities/badge-helper';
import { GetRatingContent } from '../utilities/date-helper';
import { FetchListFilters } from '../utilities/fetch-filters';

const API_URL = `${ENV.middlewareBaseUrl}/api/v1/survey`;

export interface Survey extends ApiBaseEntity {
    userId?: string;
    userName?: string;
    rating: number;
    feedback?: string;
    type: SurveyTypeList;
    createdDate?: Date;
}

export enum SurveyTypeList {
    PostAppointment = 'postAppointment',
}

export interface SurveyCategory {
    name: string;
    value: string;
    emoji: any;
}

export interface SurveyFetchListFilters extends FetchListFilters {
    ratings?: string[];
}

const customStyle = {
    defaultLabel: { minWidth: 200 },
    defaultFormControl: { marginBottom: 0 },
};

export const SURVEY_COLUMNS = [
    { label: 'Survey Type', type: 'badgeSurvey', field: 'type', width: 150 },
    { label: 'Date', type: 'dateOnly', field: 'createdDate', width: 120 },
    { label: 'Customer Name', type: 'string', field: 'userName', width: 200 },
    { label: 'Rating', type: 'rating', field: 'rating', width: 150 },
    { label: 'Additional feedback', type: 'string', field: 'feedback' },
];

export const PageSurvey = () => {
    const { fetchEntityList } = useVetdeskMiddleware<Survey>();

    const [list, setList] = useState<ApiListResponse<Survey> | undefined>(
        undefined
    );

    const [ratings, setRatings] = useState<SurveyCategory[] | undefined>([
        { value: '1', name: 'Very Dissatisfied', emoji: '😞' },
        { value: '2', name: 'Dissatisfied', emoji: '😔' },
        { value: '3', name: 'Neutral', emoji: '😐' },
        { value: '4', name: 'Satisfied', emoji: '😊' },
        { value: '5', name: 'Very Satisfied', emoji: '😁' },
    ]);

    const [selected, setSelected] = useState<Survey | undefined>(undefined);

    const [filters, setFilters] = useState<SurveyFetchListFilters>({
        search: '',
        limit: 10,
        offset: 0,
        sortBy: 'createdDate',
        sortOrderList: 'desc',
        ratings: [],
    });

    const [searchInput, setSearchInput] = useState<string>('');

    const debouncedSearchInput = useDebounce<string>(searchInput, 500);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchInput(event.target.value);
    };

    const handlePageChange = (page: number) => {
        setFilters({
            ...filters,
            offset: page * filters.limit,
        });
    };

    const fetchList = () => {
        setList(undefined); // to display skeleton when fetching
        fetchEntityList(`${API_URL}${getCustomQueryString(filters)}`).then(
            (res) => {
                setList(res);
            }
        );
    };

    const getCustomQueryString = (obj: SurveyFetchListFilters) => {
        const queryParams = new URLSearchParams({
            offset: obj.offset.toString(),
            limit: obj.limit.toString(),
        });

        // Add search query parameter if it's not empty
        if (obj.search) {
            queryParams.append('search', obj.search);
        }

        // Add each category as a separate 'category' key in the query string
        if (obj.ratings && obj.ratings.length > 0) {
            obj.ratings.forEach((category) => {
                queryParams.append('category', category);
            });
        }

        // Add sortBy and sortOrderList query parameters if provided
        if (obj.sortBy) {
            queryParams.append('sortBy', obj.sortBy);
        }

        if (obj.sortOrderList) {
            queryParams.append('sortOrderList', obj.sortOrderList);
        }

        // Convert to query string
        return `?${queryParams.toString()}`;
    };

    useEffect(() => {
        fetchList();
    }, [filters]);

    useEffect(() => {
        // if statement to avoid calling the fetch endpoint twice during mounting
        if (debouncedSearchInput !== filters.search) {
            console.log('debouncedSearchInput', debouncedSearchInput);
            setFilters((prevFilters) => ({
                ...prevFilters,
                search: debouncedSearchInput,
                offset: 0,
            }));
        }
    }, [debouncedSearchInput]);

    // Function to add a category only if it doesn't already exist
    const handleAddCategoryToFilter = (category: string) => {
        setFilters((prevFilters) => {
            const newCategoryArray = prevFilters.ratings?.includes(category)
                ? prevFilters.ratings.filter((cat) => cat !== category) // Remove the category if it exists
                : [...(prevFilters.ratings as []), category]; // Add the category if it doesn't exist

            return {
                ...prevFilters,
                ratings: newCategoryArray,
            };
        });
    };

    const renderRatings = () => {
        return (
            <Stack
                style={{ margin: '20px 0px' }}
                spacing="spacingS"
                flexWrap="wrap"
            >
                <Text>Filter by:</Text>
                <Button
                    size="small"
                    onClick={() =>
                        setFilters((prevFilters) => ({
                            ...prevFilters,
                            ratings: [],
                        }))
                    }
                    variant={
                        filters.ratings?.length == 0 ? 'primary' : 'secondary'
                    }
                >
                    All
                </Button>
                {ratings?.map((x, i) => (
                    <Button
                        key={i}
                        size="small"
                        variant={
                            filters.ratings?.includes(x.value)
                                ? 'primary'
                                : 'secondary'
                        }
                        onClick={() => handleAddCategoryToFilter(x.value)}
                    >
                        {x.emoji} {x.name}
                    </Button>
                ))}
            </Stack>
        );
    };

    const handleUpsert = (formData: Survey) => {
        setSelected(formData);
    };

    const renderModal = () => {
        return (
            <Modal
                onClose={() => setSelected(undefined)}
                isShown={!!selected}
                size="900px"
                shouldCloseOnOverlayClick={false}
            >
                {() => (
                    <>
                        <Modal.Header
                            title="Survey detail"
                            onClose={() => {
                                setSelected(undefined);
                            }}
                        />
                        <Modal.Content>
                            <FormControl style={customStyle.defaultFormControl}>
                                <FormControl.Label
                                    style={customStyle.defaultLabel}
                                >
                                    Type:
                                </FormControl.Label>
                                <Text>
                                    {selected &&
                                        getBadgeLabelForSurveyType(
                                            selected.type
                                        )}
                                </Text>
                            </FormControl>

                            <FormControl style={customStyle.defaultFormControl}>
                                <FormControl.Label
                                    style={customStyle.defaultLabel}
                                >
                                    Date:
                                </FormControl.Label>
                                <Text>
                                    {selected &&
                                        selected.createdDate &&
                                        formatDateAndTime(selected.createdDate)}
                                </Text>
                            </FormControl>

                            <FormControl style={customStyle.defaultFormControl}>
                                <FormControl.Label
                                    style={customStyle.defaultLabel}
                                >
                                    Customer name:
                                </FormControl.Label>
                                <Text>{selected?.userName}</Text>
                            </FormControl>

                            <FormControl style={customStyle.defaultFormControl}>
                                <FormControl.Label
                                    style={customStyle.defaultLabel}
                                >
                                    Rating:
                                </FormControl.Label>
                                <Text>
                                    {selected &&
                                        selected.rating &&
                                        GetRatingContent(selected?.rating)}
                                </Text>
                            </FormControl>

                            <FormControl style={customStyle.defaultFormControl}>
                                <FormControl.Label
                                    style={customStyle.defaultLabel}
                                >
                                    Additional feedback:
                                </FormControl.Label>
                                <Text>{selected?.feedback}</Text>
                            </FormControl>
                        </Modal.Content>
                        <Modal.Controls>
                            <Button
                                size="small"
                                variant="secondary"
                                onClick={() => {
                                    setSelected(undefined);
                                }}
                            >
                                Close
                            </Button>
                        </Modal.Controls>
                    </>
                )}
            </Modal>
        );
    };

    return (
        <>
            <Stack justifyContent="space-between">
                <Heading>Survey</Heading>
            </Stack>
            <Flex className="spacingTop" flexDirection="column">
                <TextInput
                    onChange={handleSearch}
                    aria-label="survey search"
                    id="searchString"
                    placeholder="search..."
                    icon={<SearchIcon />}
                />
                {ratings ? (
                    renderRatings()
                ) : (
                    <Skeleton.Row rowCount={1} columnCount={5} />
                )}
                {list ? (
                    <>
                        <TableList<Survey>
                            columns={SURVEY_COLUMNS}
                            items={list?.items}
                            onView={handleUpsert}
                        />
                        <PaginationWrapper
                            onPageChange={handlePageChange}
                            filters={filters}
                            totalItems={list.total}
                        />
                    </>
                ) : (
                    <Skeleton.Row rowCount={5} columnCount={5} />
                )}
            </Flex>
            {selected && renderModal()}
        </>
    );
};
