import React from 'react';

import {
    Badge,
    Button,
    ButtonGroup,
    formatDateAndTime,
    Modal,
    Paragraph,
    Stack,
} from '@contentful/f36-components';

import { ENV } from '../environments';
import { useVetdeskMiddleware } from '../hooks';
import { ApiReportedEntryStatus } from '../models';
import { PhotoCompetitionEntry } from '../models/photoCompetitions';

export interface ReportedEntryProps {
    entry: PhotoCompetitionEntry;
    onClose: () => void;
    onUpdate: () => void;
}

export const EntryDetail = (props: ReportedEntryProps) => {
    const entry: PhotoCompetitionEntry = props.entry;

    const { runRequest } = useVetdeskMiddleware();

    const handleReviewRequest = (value: boolean) => {
        runRequest(
            'POST',
            `${ENV.middlewareBaseUrl}/api/v1/admin/entry/${entry.id}/review`,
            {
                isInappropriate: value,
            }
        ).then(
            () => {
                props.onUpdate();
            },
            (error) => console.warn('Oops', error)
        );
    };

    const renderReportStatusBadge = (data: PhotoCompetitionEntry) => {
        return (
            <>
                {data.reportStatus == ApiReportedEntryStatus.INAPPROPRIATE ? (
                    <Stack>
                        <Badge variant="negative">
                            This entry is marked as inappropriate and hidden for
                            viewer.
                        </Badge>
                        <Button
                            variant="positive"
                            size="small"
                            onClick={() => handleReviewRequest(false)}
                        >
                            Mark this entry as safe for viewer
                        </Button>
                    </Stack>
                ) : (
                    <Stack>
                        <Badge variant="positive">
                            This entry is marked as safe for viewer.
                        </Badge>

                        <Button
                            variant="negative"
                            size="small"
                            onClick={() => handleReviewRequest(true)}
                        >
                            Mark this entry as inappropriate
                        </Button>
                    </Stack>
                )}
            </>
        );
    };

    return (
        <Modal
            size="large"
            onClose={props.onClose}
            isShown={entry != undefined}
        >
            {() => (
                <>
                    <Modal.Header
                        title="Entry Report"
                        onClose={props.onClose}
                    />
                    {!entry.reportedByCustomer ? (
                        <Modal.Content>
                            <Paragraph>
                                <strong>No report found for this entry.</strong>
                            </Paragraph>
                            {entry.reportStatus ==
                            ApiReportedEntryStatus.NONE ? (
                                <>
                                    <Paragraph>
                                        <strong>
                                            Click the button below if you think
                                            this entry is inappropriate.
                                        </strong>
                                    </Paragraph>
                                    <ButtonGroup
                                        variant="spaced"
                                        spacing="spacingM"
                                    >
                                        <Button
                                            style={{ marginBottom: 25 }}
                                            variant="negative"
                                            size="small"
                                            onClick={() =>
                                                handleReviewRequest(true)
                                            }
                                        >
                                            Mark this entry as inappropriate
                                        </Button>
                                    </ButtonGroup>
                                </>
                            ) : (
                                renderReportStatusBadge(entry)
                            )}
                        </Modal.Content>
                    ) : (
                        <Modal.Content>
                            <Paragraph>
                                This entry has been reported by{' '}
                                {entry.reportedEntries.length} user/s as{' '}
                                inappropriate and this was enterd by{' '}
                                <strong>{entry.username}</strong> on{' '}
                                {formatDateAndTime(entry.entryDate)}.
                            </Paragraph>
                            {entry.requiresReview ? (
                                <ButtonGroup
                                    variant="spaced"
                                    spacing="spacingM"
                                >
                                    <Button
                                        variant="positive"
                                        size="small"
                                        onClick={() =>
                                            handleReviewRequest(false)
                                        }
                                    >
                                        Mark this entry as safe for viewer
                                    </Button>
                                    <Button
                                        variant="negative"
                                        size="small"
                                        onClick={() =>
                                            handleReviewRequest(true)
                                        }
                                    >
                                        Mark this entry as inappropriate
                                    </Button>
                                </ButtonGroup>
                            ) : (
                                <Paragraph>
                                    <strong>Review Result:</strong>{' '}
                                    {renderReportStatusBadge(entry)}
                                </Paragraph>
                            )}

                            <p style={{ marginTop: 15 }}>
                                <strong>Reporters Detail:</strong>
                            </p>

                            {entry.reportedEntries.map((report, index) => {
                                return (
                                    <p key={index}>
                                        {index + 1}. {report.reporterName}{' '}
                                        <strong>Reason:</strong> {report.reason}
                                    </p>
                                );
                            })}
                        </Modal.Content>
                    )}
                </>
            )}
        </Modal>
    );
};
